import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import QuoteIconGrey from '../images/icons/theme/text/quote-1-grey.svg';
import DecorationDots6 from '../images/decorations/deco-dots-6.svg';
import AgentadvanceReview from '../images/logos/agentadvance-review.svg';
import QoloReview from '../images/logos/qolo-review.svg';
import ThinkpricingReview from '../images/logos/thinkpricing-review.svg';
import VideoPlay from '../images/icons/interface/videoPlay.svg';
import Star from '../images/icons/interface/star.svg';
import Modal from 'react-bootstrap/Modal';

const Testimonials = ({ data }) => {
  const agentAvatar = data.agentAvatar.childImageSharp.gatsbyImageData;
  const thinkpricingAvatar = data.thinkpricingAvatar.childImageSharp.gatsbyImageData;
  const qoloAvatar = data.qoloAvatar.childImageSharp.gatsbyImageData;
  const almAvatar = data.almAvatar.childImageSharp.gatsbyImageData;
  const wevettAvatar = data.wevettAvatar.childImageSharp.gatsbyImageData;

  const [showModal, setShowModal] = useState(false);
  const [videoId, setVideoId] = useState('');

  const handleVideoClick = (id) => {
    setVideoId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setVideoId('');
  };

  return (
    <>
    <section className="o-hidden py-5 fifth-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-flex align-items-center text-center">
            <div className="d-flex flex-column" style={{ maxWidth: '720px', margin: '0 auto' }}>
              <DecorationDots6 className="bg-yellow mb-2" />
              <h2 className='display-4 text-center'>Here's how we've helped our clients</h2>
                 <a
                id="freeconsultation"
                href="https://clutch.co/profile/adam-fard-ux-studio#reviews"
                className="mt-2 mb-2 mb-md-0 hover-arrow testimonial-cta lead text-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                See all reviews
              </a>
            </div>
          </div>
        </div>

        <div className="row mt-4">
            <div className="col-12">
                <div className="testimonial-video-row d-flex flex-column flex-md-row">
                    <div className='testimonial-container testimonial-video position-relative w-100 w-md-50 mb-2 mb-md-0'
                        onClick={() => handleVideoClick('5RpIAqU-Jdo')}
                    >
                        <GatsbyImage
                        image={almAvatar}
                        alt=""
                        className="h-100 w-100"
                        objectFit="cover"
                        objectPosition="center"
                        />
                        <VideoPlay className="video-play-icon" />
                        <div className="position-absolute bottom-0 start-0 p-3 text-white" style={{ transform: 'translateY(-100%)', zIndex: 2 }}>
                            <div className="d-flex flex-column">
                                <span className="me-2 text-primary-2 h3 mb-0">
                                    <Star /><Star /><Star /><Star /><Star />
                                </span>
                                <span className="fw-bold h2 text-white mb-0">Richard Caruso</span>
                            </div>
                            <div>Senior VP of products at ALM</div>
                        </div>
                    </div>
                    <div className='testimonial-container testimonial-video position-relative w-100 w-md-50 mb-0'
                        onClick={() => handleVideoClick('t63avS52NyY')}
                    >
                        <GatsbyImage
                        image={wevettAvatar}
                        alt=""
                        className="h-100 w-100"
                        objectFit="cover"
                        objectPosition="center"
                        />
                        <VideoPlay className="video-play-icon" />
                        <div className="position-absolute bottom-0 start-0 p-3 text-white" style={{ transform: 'translateY(-100%)', zIndex: 2 }}>
                            <div className="d-flex flex-column">
                                <span className="me-2 text-primary-2 h3 mb-0">
                                    <Star /><Star /><Star /><Star /><Star />
                                </span>
                                <span className="fw-bold h2 text-white mb-0">Shannon Kaufman</span>
                            </div>
                            <div>Co-Founder of WeVett</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row mt-2 mt-md-3 testimonial-row">
            <div className="col-12 col-md-4 mb-2 mb-md-0">
                <div className="testimonial-container d-flex flex-column h-100">
                <QuoteIconGrey className="quote-icon" />
                <div className="testimonial-wrapper flex-grow-1">
                    <p className="mb-3 testimonial">
                    Adam Fard UX Studio gave us the hands-on feeling of a small
                    shop and the quality and professionalism of a big shop.
                    </p>
                    <div className="client-wrapper align-items-center mt-auto">
                    <GatsbyImage
                        image={agentAvatar}
                        style={{ width: '64px', height: '64px' }}
                        imgClassName="avatar align-items-left mr-1"
                        loading="lazy"
                        alt=""
                    />
                    <AgentadvanceReview className="client-company" />
                    </div>
                </div>
                </div>
            </div>
            
            <div className="col-12 col-md-4 mb-2 mb-md-0">
                <div className="testimonial-container d-flex flex-column h-100">
                <QuoteIconGrey className="quote-icon" />
                <div className="testimonial-wrapper flex-grow-1">
                    <p className="mb-3 testimonial">
                    The final UX design meets all our requirements and looks
                    great. The team is efficient and to the point. impressively
                    able to understand design needs and integrate feedback.
                    </p>
                    <div className="client-wrapper align-items-center mt-auto">
                    <GatsbyImage
                        image={thinkpricingAvatar}
                        style={{ width: '64px', height: '64px' }}
                        imgClassName="avatar align-items-left mr-1"
                        loading="lazy"
                        alt=""
                    />
                    <ThinkpricingReview
                        className="client-company"
                        style={{ width: '178px' }}
                    />
                    </div>
                </div>
                </div>
            </div>
            
            <div className="col-12 col-md-4">
                <div className="testimonial-container d-flex flex-column h-100">
                <QuoteIconGrey className="quote-icon" />
                <div className="testimonial-wrapper flex-grow-1">
                    <p className="mb-3 testimonial">
                    The MVP received positive feedback from the internal team.
                    Adam Fard UX studio creates high-quality designs that meet the
                    needs of the client. The team is hard-working, innovative, and
                    driven.
                    </p>
                    <div className="client-wrapper align-items-center mt-auto">
                    <GatsbyImage
                        image={qoloAvatar}
                        style={{ width: '64px', height: '64px' }}
                        imgClassName="avatar align-items-left mr-1"
                        loading="lazy"
                        alt=""
                    />
                    <QoloReview className="client-company" />
                    </div>
                </div>
                </div>
            </div>
            </div>
      </div>
    </section>

       <Modal
        show={showModal}
        onHide={closeModal}
        centered
        size="lg"
      >
        <Modal.Body className="p-0">
          {videoId && (
            <iframe
              width="100%"
              height="480"
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&modestbranding=1&controls=1&showinfo=0`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Testimonials;
